<template>
    <select
       @change="onChange"
      class="form-select mt-10 block w-full border p-3 rounded" 
      v-model="selected">
      <option value="0">Одбери Земја</option>
      <option :key="country.ID" v-for="country in countries" :value="country.ID">{{country.Country}}</option>
    </select>
</template>

<script>
export default {
    name: 'CountrySelect',
    props: ['countries'],
    data() {
      return {
        selected: 0
      }
    },
    methods: {
      onChange() {
        const selCountry = this.countries.find((item) => item.ID === this.selected)
        this.$emit('get-country', selCountry)
      }
    }
}
</script>

<style>

</style>