<template>
  <div class="grid md:grid-cols-2 gap-4">
      <!-- Box 1 -->
      <div class="shadow-md bg-blue-300 p-10 text-center rounded">
          <h3 class="text-3xl text-blue font-bold-900 mb-4">Случаеви</h3>

          <div class="text-2xl mb-4">
              <span class="font-bold">Нови:</span>
              {{numberWithCommas(stats.NewConfirmed)}}
          </div>
          <div class="text-2xl mb-4">
              <span class="font-bold">Вкупно:</span>
              {{numberWithCommas(stats.TotalConfirmed)}}
          </div>
      </div>
      <!-- Box 2 -->
      <div class="shadow-md bg-red-300 p-10 text-center rounded">
          <h3 class="text-3xl text-blue font-bold-900 mb-4">Починати</h3>

          <div class="text-2xl mb-4">
              <span class="font-bold">Нови:</span>
              {{numberWithCommas(stats.NewDeaths)}}
          </div>
          <div class="text-2xl mb-4">
              <span class="font-bold">Вкупно:</span>
              {{numberWithCommas(stats.TotalDeaths)}}
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'DataBoxes',
    props: ['stats'],
    methods: {
        numberWithCommas(x){
            return x.toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
    }
}
</script>