<template>
  <div class="text-center">
      <h2 class="text-3xl font-bold">{{text}}</h2>
      <div class="text-2xl mt-4 mb-10">{{timestamp}}</div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
    name: 'DataTitle',
    props: ['text', 'dataDate'],
    computed: {
        timestamp: function() {
            return moment(this.dataDate).locale("mk").format('MMMM Do YYYY, HH:mm')
        }
    }
}
</script>