<template>
  <header class="text-center bg-blue-800 text-white p-4 mb-10">
      <div class="text-3xl md:text-5xl font-bold mb-3"><i class="fa fa-viruses"></i> Ковид 19 Статистика</div>

    <p>Овозможено од
        <a target="_blank" href="https://covid19api.com" class="text-blue-300">covid19api.com</a>
    </p>
  </header>
</template>

<script>
export default {
    name: 'Header'
}
</script>