<template>
  <main v-if="!loading">
    <dataTitle :text="title" :dataDate="dataDate" />

    <DataBoxes :stats="stats" />

    <CountrySelect @get-country="getCountryData" :countries="countries" />
  </main>
  <main v-else class="flex flex-col align-center justify-center">
    <div class="text-gray-400 text-3xl mt-10 mb-6">Вчитување на податоците...</div>
    <img :src="loadingImage" alt="" class="w-24 m-auto" />
  </main>
</template>

<script>
import DataTitle from '@/components/DataTitle'
import DataBoxes from '@/components/DataBoxes'
import CountrySelect from '@/components/CountrySelect'

export default {
  name: 'Home',
  components: {
    DataTitle,
    DataBoxes,
    CountrySelect
  },
  data() {
    return {
      loading: true,
      title: 'Свет',
      dataDate: '',
      stats: {},
      countries: [],
      loadingImage: require('../assets/loading.gif')
    }
  },
  methods: {
    async fetchCovidData() {
      const res = await fetch('https://api.covid19api.com/summary')
      const data = await res.json()
      return data
    },
    async getCountryData(country){
      if(typeof country !== 'undefined'){
        this.stats = country
        this.title = country.Country
      }else{    
        this.loading = true
        const data = await this.fetchCovidData()
        this.title = 'Свет'
        this.stats = data.Global
        this.loading = false
      }
    }
  },
  async created() {
    const data = await this.fetchCovidData()
    
    this.dataDate = data.Date
    this.stats = data.Global
    this.countries = data.Countries
    this.loading = false
  }
}
</script>
